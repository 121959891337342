jQuery(document).ready(function ($) {
    if ($('#home_page').length > 0) {
        $('.circ-group-1 ').css({
            'top': '6rem',
            'left': '-10rem',
        });
        $('.circ-group-2 ').css({
            'bottom': '10rem',
            'left': '25rem',
        });
        $('.circ-2 ').css({
            'bottom': '14rem',
            'left': '-10rem',
        });
        $('.circles ').css({

            'right': '-20%',
        });
        $('.right-col  ').css({
            'top': '0',
            'right': '0',
        });
        $('.left-col  ').css({
            'opacity': '1',
            'right': '0',
        });
    }
});